<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">invite user</div>
        </portal>
        
        <ActionButton @click="closeAction">Manage Invited Users</ActionButton>
      </div>
      
      <div>
        <CancelButton @click="closeAction">Close</CancelButton>
      </div>
    </div>
  
    <v-card
      class="create-card cardBg py-15 px-12 rounded-20"
      :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
      flat
      outlined
    >
      <v-row class="setup-content d-block d-md-flex align-center" no-gutters>
        <v-col cols="12" md="4" lg="2">
          <TextDescription :description="description" />
        </v-col>
      
        <v-col cols="12" md="8" lg="10">
          <v-form ref="form" v-model="validateForm">
            <v-row no-gutters>
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">First name</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="inviteUserFirstName"
                ></portal-target>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <FormField
                  v-model="formDataUser['first_name']"
                  :rules="nameRules"
                  :loading="loadingDetailUser"
                  class="field-container"
                >
                  <template #message="{ key, message }">
                    <portal to="inviteUserFirstName" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">Last name</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="inviteUserLastName"
                ></portal-target>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <FormField
                  v-model="formDataUser['last_name']"
                  :rules="nameRules"
                  :loading="loadingDetailUser"
                  class="field-container"
                >
                  <template #message="{ key, message }">
                    <portal to="inviteUserLastName" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">Email</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="inviteUserEmail"
                ></portal-target>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <FormField
                  v-model="formDataUser.email"
                  :rules="emailRules"
                  :loading="loadingDetailUser"
                  class="field-container"
                >
                  <template #message="{ key, message }">
                    <portal to="inviteUserEmail" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text text-sm-right">Invite to</span>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <ToggleButton v-model="currentOptions" :buttons="userRoleOptions"/>
              </v-col>
            
              <v-scroll-y-transition hide-on-leave>
                <v-col cols="12" v-if="currentOptions === 1">
                  <v-row no-gutters>
                    <v-col
                      class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                      cols="12" sm="5" md="3"
                    >
                      <span class="font-normal nameField--text text-sm-right">Buyers</span>
                      <portal-target
                        class="field-message font-normal error--text text-right text-sm-right"
                        name="inviteToBuyer"
                      ></portal-target>
                    </v-col>
                  
                    <v-col class="pb-10" cols="12" sm="7" md="9">
                      <AutocompleteField
                        class="field-container"
                        v-model="buyer"
                        :loading="loadingAllBuyers"
                        :items="buyersAll"
                        item-text="name"
                        item-value="slug"
                        :rules="rules"
                        checkBg
                      >
                        <template #message="{ key, message }">
                          <portal to="inviteToBuyer" v-if="!!message">
                            {{ message }}
                          </portal>
                        </template>
                      </AutocompleteField>
                    </v-col>
                  </v-row>
                </v-col>
              </v-scroll-y-transition>
            
              <v-scroll-y-transition hide-on-leave>
                <v-col cols="12" v-if="currentOptions === 2">
                  <v-row no-gutters>
                    <v-col
                      class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                      cols="12" sm="5" md="3"
                    >
                      <span class="font-normal nameField--text text-sm-right">Vendors</span>
                      <portal-target
                        class="field-message font-normal error--text text-right text-sm-right"
                        name="inviteToVendor"
                      ></portal-target>
                    </v-col>
                  
                    <v-col class="pb-10" cols="12" sm="7" md="9">
                      <AutocompleteField
                        class="field-container"
                        v-model="vendor"
                        :loading="loadingAllVendors"
                        :items="vendorsAll"
                        item-text="name"
                        item-value="slug"
                        :rules="rules"
                        checkBg
                      >
                        <template #message="{ key, message }">
                          <portal to="inviteToVendor" v-if="!!message">
                            {{ message }}
                          </portal>
                        </template>
                      </AutocompleteField>
                    </v-col>
                  </v-row>
                </v-col>
              </v-scroll-y-transition>
            
              <v-col cols="12" sm="7" md="9" offset="0" offset-sm="5" offset-md="3" class="mt-2 mt-sm-8">
                <CancelButton @click="closeAction" class="mr-8 mr-sm-10">Cancel</CancelButton>
              
                <ActionButton type="submit" @click.prevent="submitForm" :loading="loading">
                  Invite
                </ActionButton>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import ToggleButton from '@/components/buttons/ToggleButton'
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import AutocompleteField from '@/components/select/AutocompleteField'

import { getAllBuyers } from '@/api/buyer-routes'
import { getAllVendors } from '@/api/vendor-routes'
import { inviteUserToBuyer, inviteUserToVendor } from '@/api/user-routes'

export default {
  name: 'InviteUser',
  components: { ActionButton, CancelButton, ToggleButton, TextDescription, FormField, AutocompleteField },
  data: () => ({
    loading: false,
    loadingDetailUser: false,
    loadingAllVendors: false,
    loadingAllBuyers: false,
    validateForm: true,
    description: {
      title: 'Invite user',
      text: []
    },
    formDataUser: {
      'first_name': null,
      'last_name': null,
      email: null,
      unit: null,
    },
    currentOptions: 1,
    userRoleOptions: [
      { name: 'Buyer', id: 1 },
      { name: 'Vendor', id: 2 },
    ],
    vendorsAll: [],
    buyersAll: [],
    vendor: null,
    buyer: null,
    rules: [],
    nameRules: [],
    emailRules: [],
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  methods: {
    async goToLoadAllBuyers() {
      if (!this.buyersAll.length) {
        this.loadingAllBuyers = true

        const { success, payload, message } = await getAllBuyers()

        if (success) {
          this.buyersAll = payload
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loadingAllBuyers = false
      }
    },
    async goToLoadAllVendors() {
      if (!this.vendorsAll.length) {
        this.loadingAllVendors = true

        const { success, payload, message } = await getAllVendors()

        if (success) {
          this.vendorsAll = payload
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loadingAllVendors = false
      }
    },
    async submitForm() {
      await this.validationForm()
      
      if (this.$refs.form.validate()) {
        const formData = Object.assign({}, this.formDataUser)
        
        if (this.currentOptions === 1) {
          formData.unit = this.buyer
          this.goToInviteToBuyer(formData)
        } else if (this.currentOptions === 2) {
          formData.unit = this.vendor
          this.goToInviteToVendor(formData)
        }
      }
    },
    async goToInviteToBuyer(formData) {
      this.loading = true
      
      const { success, message } = await inviteUserToBuyer(formData)
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.formReset()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    async goToInviteToVendor(formData) {
      this.loading = true

      const { success, message } = await inviteUserToVendor(formData)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.formReset()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    validationForm() {
      this.emailRules = [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid'
      ]
      this.nameRules = [
        v => !!v || 'This field is required',
        v => !!v && v.trim().length >= 2 || 'Name cannot be shorter than two characters'
      ]
      this.rules = [
        v => !!v || 'This field is required',
      ]
    },
    formReset() {
      this.buyer = null
      this.vendor = null
      this.formDataUser = {
        'first_name': null,
        'last_name': null,
        email: null,
        unit: null,
      }
      this.rules = []
      this.emailRules = []
      this.nameRules = []
    },
    closeAction() {
      this.$router.push({ name: 'ManageUsers' })
    },
  },
  watch: {
    currentOptions: {
      immediate: true,
      handler(opt) {
        if (opt === 1) {
          this.goToLoadAllBuyers()
          this.vendor = null
        } else if (opt === 2) {
          this.goToLoadAllVendors()
          this.buyer = null
        }
      }
    },
  },
}
</script>
